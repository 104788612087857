import React from "react"
import Layout from "../components/_App/layout"
import SEO from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import Team from "../components/Common/Team"
import FunFactsArea from "../components/Common/FunFactsArea"
import Footer from "../components/_App/Footer"
import PageBanner from "../components/Common/PageBanner"
import Partner from "../components/MachineLearning/Partner"

import AboutImg from "../assets/images/about-image/about.png"

// Shape Images
import Shape2 from "../assets/images/shape2.svg"
import Shape3 from "../assets/images/shape3.svg"
import Shape4 from "../assets/images/shape4.svg"

const About = () => (
  <Layout>
    <SEO title="About Us" />

    <Navbar />

    {/* <PageBanner pageTitle="About Us" /> */}

    <div className="agency-about-area ptb-80">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="agency-about-img">
              <img src={AboutImg} alt="image" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="agency-about-content">
              <span className="sub-title">About Us</span>
              <h2>Systems and Design Thinking Collide</h2>

              <p>
                Faraya utilizes a systems engineering approach to project
                planning and design, and drives projects forward with an Agile
                methodology that allows us to quickly handle and adapt to our
                client's changing needs.
              </p>

              <p>
                Our work begins with a discovery call at your convenience. The
                Faraya team will then map your requirements and desired outcomes
                to our services and interdisciplinary talent. From there, we
                will deliver a statement of work that fully describes the work
                we will conduct, tied to agreed upon timelines and costs. Upon
                agreeing on a Statement of Work, Faraya will rapidly
                operationalize a team to get started and will work
                month-to-month, without requiring a long-term commitment.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="about-inner-area">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="about-text">
                <h3>Our History</h3>
                <p>
                  Faraya's leadership team has worked together for over five
                  years implementing advanced solutions for clients. Faraya LLC
                  was incorporated in 2021, and represents a new, international
                  venture, executing advanced projects and delivering innovative
                  solutions.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="about-text">
                <h3>Our Mission</h3>
                <p>
                  To identify and operationalize the world's most advanced
                  network of talent, which functions to deliver ideas,
                  solutions, and services that improve the lives of people, and
                  which meet the desired outcomes of our clients.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="about-text">
                <h3>Who We Are</h3>
                <p>
                  Faraya is a team of diverse innovators with expert, technical
                  capabilities that are leveraged to meet our client's needs.
                  Faraya hand-selects youthful, exceptional talent, and provides
                  opportunities for these individuals beyond what they would
                  traditionally find in industry. This allows us to deliver
                  world-class outcomes at competitive rates.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Shape Images */}
      <div className="shape2 rotateme">
        <img src={Shape2} alt="shape" />
      </div>
      <div className="shape3">
        <img src={Shape3} alt="shape" />
      </div>
      <div className="shape4">
        <img src={Shape4} alt="shape" />
      </div>
      <div className="shape7">
        <img src={Shape4} alt="shape" />
      </div>
      <div className="shape8 rotateme">
        <img src={Shape2} alt="shape" />
      </div>
    </div>

    {/* <Team /> */}
    {/* 
        <FeedbackStyleThree />
        
        <FunFactsArea /> */}

    {/* <div className="pb-80">
            <Partner />
        </div> */}

    <Footer />
  </Layout>
)

export default About
